html {
  font-size: 10px;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.6rem;
  background-color: #202225;
  overflow-x: hidden;
}

body, #root, .App {
  box-sizing: border-box;
  position: relative;
  height: 100vh;
  width: 100vw;
}

.App {
  padding: 4rem;
}

::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color:#36393F;
  border-radius: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #707070;
  border-radius: 8px;
}

a {
  color: #FFFFFF;
}

p {
  color: #707070;
  margin: 1rem;
  user-select: none;
}

.reactour__helper--is-open {
  line-height: 1.6;
  font-size: 18px;
}

.reactour__helper--is-open > span {
  background-color: #6B2FBC;
}

.reactour__dot--is-active {
  background: #6B2FBC !important;
}

.reactour__close {
  top: 12px !important;
  right: 12px !important;
  width: 11px !important;
  height: 11px !important;
}